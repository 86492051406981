<template>
  <div class="top-card mb-4">
    <v-card
      color="neutral"
      class="rounded-xl"
    >
      <v-card-text>
        <div class="v-toolbar__title mb-4">
          {{ $t('Pages.Company.details') }}
        </div>
        <v-form
          id="company-details"
          ref="form"
          v-model="validForm"
          @submit.prevent="onSaveCompanyDetails()"
        >
          <v-text-field
            id="company-org"
            v-model="companyDetails.organisationNumber"
            type="text"
            color="accent"
            :label="$t('Pages.Company.organisation_number')"
            :rules="[rules.required]"
            required
            :disabled="!isAdminForCompany"
            rounded
            outlined
          />
          <v-text-field
            id="company-vat"
            v-model="companyDetails.vatNumber"
            type="text"
            color="accent"
            :label="$t('Pages.Company.vat')"
            :rules="[rules.required]"
            required
            :disabled="!isAdminForCompany"
            rounded
            outlined
          />
          <em v-if="!isAdminForCompany">Contact your company administrator if something looks wrong.</em>
          <div
            v-if="isAdminForCompany"
            class="button-container"
          >
            <v-btn
              id="save-btn-account"
              :loading="loadingForm"
              :disabled="!validForm || loadingForm"
              class="cta-btn"
              rounded
              type="submit"
              v-text="$t('Common.save')"
            />
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { rules } from '@/config/validation-rules'
import { mapState } from 'vuex'
import { SAVE_COMPANY_DETAILS } from '@/store/actions.type'

export default {
  name: 'CompanyMembers',
  components: {},
  data () {
    return {
      rules,
      validForm: false,
      loadingForm: false,
      isAdminForCompany: true // TODO: Attach logic here
    }
  },
  computed: {
    ...mapState([
      'adminForCompanies',
      'companyDetails'
    ])
  },
  methods: {
    onSaveCompanyDetails () {
      if (this.$refs.form.validate()) {
        // save company details
        this.loadingForm = true
        this.$store.dispatch(SAVE_COMPANY_DETAILS, this.companyDetails).then(
          (res) => {
            this.loadingForm = false
          }, err => {
            this.loadingForm = false
            console.error(err)
            this.$root.$emit('errorMessage', err.response)
          }
        )
      }
    }
  }
}
</script>
